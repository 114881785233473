/**
 * 导出通用配置
 */
module.exports = {
  // 标题，此项修改后需要重启项目
  title: '亿研云导航',
  // 网站标题
  siteTitle: '亿研云丨导航页 - 可自定义的简洁导航网站',
  // 网站加载时显示的内容
  siteLoading: '正在为您加载系统资源，请耐心等待',
  // 网站描述
  siteDescription:
    '亿研云丨导航页(yyzbd.cc)是一个可自定义、简洁又好用的导航网站，国内屈指可数的自定义导航，用户可以自定义导航、布局设置等。收录大量各类优秀网站，包含热门导航、格式转换、图片素材、PPT资源、桌面壁纸、资源搜索、在线工具、素材资源、自媒体等。网站大全就选格姗导航！',
  // 网站关键字
  siteKeywords:
    '亿研云导航页,导航,导航网站,简洁,简洁导航,好用的导航网站,网站大全,亿研云,知识圈,自定义导航',
  // 白名单配置，不用令牌就能访问
  whileList: ['/', '/login', '/collect', '/about', '/log', '/setIndex', '/jy'],
  // 超级管理员
  superAdmin: 'superAdmin',
  // 所有权限
  allPermission: '*:*:*',
  // 备案号
  recordTitle: '豫ICP备19009358号-27',
  // 设为首页联系方式
  setIndexConcat: '亿研（微信号：YY17154442222）'
}
