import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

// 获取验证码
export function getCaptchaImage() {
  return request({
    url: '/getCaptchaImage',
    method: 'get'
  })
}

// 退出登录
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

/**
 * 获取登录地址
 */
export function getLoginUrl(source) {
  return request({
    url: `/auth/third/login/getLoginUrl/${source}`,
    method: 'get'
  })
}
